<template>
  <div class="page">
    <vue-markdown :html="true" :source="source" @rendered="window.setTimeout(() => { window.Prism.highlightAll();});" />
  </div>
</template>

<style lang="scss">
@import 'larva';

 pre[class*="language-"] {
    padding: 0.4rem;
    line-height: 1.2 !important;
    font-size: 0.78rem !important;
    tab-size: 2;
    text-shadow: 0 0
 }
 pre[class*="language-"] a {
   color: $light;
   text-decoration: underline;
 }
 .page {
   margin: 1rem;
 }
</style>

<script>
import VueMarkdown from 'vue-markdown';
import frontMatter from 'front-matter';

export default {
  components: {
    VueMarkdown
  },
  props: ['base', 'file'],
  data () {
    return {
      source: '',
      window
    };
  },
  watch: {
    base () {
      this.fetchFile();
    },
    file () {
      this.fetchFile();
    }
  },
  created () {
    this.fetchFile();
  },
  methods: {
    fetchFile () {
      const file = `/contents/${this.base}${this.base?'/':''}${this.file}.md`;
      this.$http.get(file)
      .then(res => {
        return res.data;
      })
      .then(frontMatter)
      .then(data => {
        // data.attributes;
        // console.log(data);
        this.source = data.body;
      }).then(()=> {
        window.Prism.highlightAll();
        window.scrollTo(0, 0);
      }).catch(err => {
        console.error(err);
        return this.$router.replace('/404/index');
      });
    }
  },
};
</script>
